<template>
    <div data-bs-theme="dark">
        <div class="qr-container">
            <canvas ref="qrcode">

            </canvas>
        </div>

        <div class="app-container">
            <div class="btn-group mb-2">
                <button @click="mode = 'single'"
                        :class="{'btn-primary': mode == 'single', 'btn-secondary': mode == 'multiple'}"
                        class="btn">
                    Single QR Code
                </button>
                <button @click="mode = 'multiple'"
                        :class="{'btn-primary': mode == 'multiple', 'btn-secondary': mode == 'single'}"
                        class="btn">
                    List of Codes
                </button>
            </div>

            <div class="input-group mb-3"
                 v-if="mode == 'single'">
                <span class="input-group-text">QR Code</span>
                <input v-model="text"  
                       @focus="selectInput"
                    class="form-control"
                    @input="createQrCode">       
            </div>
            <div v-else
                 class="input-group mb-3">
                <textarea v-model="qrCodeList"
                          class="form-control"
                          placeholder="One QR code per line"
                          style="min-height: 250px; overflow-y: scroll; padding:8px; white-space: nowrap; overflow-wrap: normal; overflow-x: scroll; font-family: 'Courier New', Courier, monospace;">
                </textarea>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text">Cell Radius</span>
                <input type="number"
                    min="0"
                    max="0.5"
                    v-model="radius"
                    step="0.05"
                    @input="createQrCode"
                    class="form-control">
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text">Size (px)</span>
                <input v-model="size"
                    type="number"
                    min="256" 
                    class="form-control"
                    @input="createQrCode">       
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text">Error Correction</span>
                <select v-model="ecLevel"
                        class="form-select"
                        @input="createQrCode">
                    <option v-for="(ecLevel, idx) in ecLevels"
                            :value="ecLevel">
                        {{ idx }}
                    </option>    
                </select>
            </div>
            <div class="input-group mb-3">
                <span class="input-group-text">Colour</span>
                <input v-model="fill"
                    type="color"
                    class="form-control"
                    style="height: 38px"
                    @input="createQrCode">       
            </div>

            <button class="btn btn-primary"
                    @click="saveQrCodeRequest">
                Save
            </button>
        </div>

        <div class="mt-5">
            <h4>How it Works</h4>

            <p>
                <strong>Single Mode</strong>: When you click Save, the browser will save your QR code to a PNG with a transparent background.

            </p>
            <p>
                <strong>List Mode</strong>: Enter one QR code per line. When you click Save, the browser will save all your QR codes to a ZIP file.
            </p>
        </div>

        <div class="text-secondary mt-5">
            &copy; <a href="https://www.tractusevents.com">Tractus Events</a>
        </div>
    </div>
</template>
<script>  
import QrCreator from 'qr-creator';
import jszip from 'jszip'
import { nextTick } from 'vue'

export default {
    data() {
        return {
            text: "My Text",
            radius: 0,
            ecLevel: "M",
            ecLevels: {
                "Low": "L",
                "Medium": "M",
                "Quarter": "Q",
                "High": "High"
            },
            fill: "#536DFE",
            background: "transparent",
            size: 256,
            mode: "single",
            qrCodeList: "",
        };
    },
    methods: {
        createQrCode() {
            const qrCode = this.$refs.qrcode;
            QrCreator.render({
                text: this.text,
                radius: this.radius,
                ecLevel: this.ecLevel,
                fill: this.fill,
                background: this.background,
                size: this.size // in pixels
            }, qrCode);
            console.log("Render called");
        },

        async saveQrCodeRequest() {

            if(this.mode == 'single') {

                let result = await this.renderQrCodeToPng(this.text);

                let link = document.createElement('a');
                link.setAttribute('download', 'qrcode.png');
                link.setAttribute('href', result);
                link.click();

            } else {

                let zip = new jszip();

                let lines = this.qrCodeList.split('\n');
                let outputKey = '';

                for(let i = 0; i < lines.length; i++) {
                    let line = lines[i];

                    let result = await this.renderQrCodeToPng(line);

                    let data = result.split(';base64,')[1];

                    zip.file(`${i}.png`, data, { base64: true });
                    outputKey += `${i}.png\r\n: ${line}`;
                }

                outputKey += '\r\nGenerated by qrcodes.tractusevents.com';

                zip.file('key.txt', outputKey);

                let zipFile = await zip.generateAsync({type: 'blob'});

                let url = URL.createObjectURL(zipFile);

                let link = document.createElement('a');
                link.setAttribute('download', 'qrcodes.zip');
                link.setAttribute('href', url);
                link.click();
            }

        },

        async renderQrCodeToPng(text) {
            this.text = text;
            await nextTick(() => {
                this.createQrCode();
            });

            const canvas = this.$refs.qrcode;
            const dataUrl = canvas.toDataURL('image/png');

            return dataUrl;
        },

        selectInput(e) {
            e.target.select();
        }
    },
    mounted() {
        if (localStorage) {
            let result = localStorage.getItem("lastQrCode");
            this.text = result || "https://www.tractusevents.com";
            let lastColor = localStorage.getItem("lastColor");
            this.fill = lastColor || "#000";
        }
        this.createQrCode();
    },
}
</script>
